<template>
  <Member
    title="Stefano Cascinu"
    description="Direttore Oncologia Medica, IRCCS- Ospedale San Raffaele e Professore ordinario di Oncologia Medica"
    text="Il Prof. Stefano Cascinu nasce a Pesaro il 21 Febbraio 1959 e dal 1992 ad oggi è responsabile di 35 progetti scientifici (AIRC; Ministero della Ricerca; Fondazione Berlucchi; Fondazione Cariverona; Fondazione CariParma; Ministero della Salute; CNR; Progetti Comunità Europea) e Principal investigator di oltre 350 sperimentazioni cliniche internazionali, la maggior parte con nuovi farmaci.


Dal 2019 è Direttore di Oncologia Medica, presso IRCCS- Ospedale San Raffaele, nonchè Professore ordinario di Oncologia Medica.


Posizioni Universitarie:

Professore Associato di Oncologia Medica, (1998-2002)

Facolta di Medicina e Chirurgia Università degli Studi di Messina          

Professore Associato di Oncologia Medica, (2002-2006)

Professore Ordinario di Oncologia Medica (2006-2105)

Direttore Scuola di Specializzazione in Oncologia medica (2010-2015)

Coordinatore Dottorato di Ricerca Oncologia

Presidente Corso di Laurea Infermieristica Polo di Pesaro (2011-2014)

Facoltà di Medicina e Chirurgia, Università Politecnica delle Marche

Professore Ordinario di Oncologia Medica (2015-2019) Università di Modena e Reggio Emilia

Professore ordinario di Oncologia Medica (2019-9

Università Vita-Salute, San Raffaele Milano.


Posizioni Ospedaliere:


Assistente Medico, U.O. Oncologia Medica, Azienda Ospedaliera di Pesaro (PU) (1990-1994)

Aiuto Medico, Clinica di Oncologia Medica, Ospedali Riuniti di Ancona, Università degli Studi di Ancona (1994-1995; 1996-1997)

House staff, Moses Division of Medical Oncology, Montefiore Hospital, New York (NY) USA (1995)

Responsabile, Sezione Terapie innovative in Oncologia, Istituto di Oncologia e Ricerca sui Tumori, Policlinico di Messina (1998-2000)

Direttore, U.O. Oncologia Medica, Azienda Ospedaliera di Parma, Università degli Studi di Parma (2000-2002)

Direttore, Clinica di Oncologia Medica, A.O. Ospedali Riuniti di Ancona, Università Politecnica delle Marche (2002-2015)

Direttore, Oncologia Medica. Policlinico di Modena (2015)

Direttore, Dipartimento integrato di Oncologia e Ematologia. Policlinico di Modena (2016-2019).

Direttore, Oncologia Medica, IRCCS- Ospedale San Raffaele (2019-)"
    :formation="[
      'Diploma di Laurea in Medicina e Chirurgia, Università degli Studi di Ancona',
      'Diploma di Specializzazione in Oncologia, Università degli Studi di Ancona',
      'Diploma di Specializzazione in Radioterapia, Università di Bologna',
      '',
    ]"
    publication="500 articoli pubblicati su riviste internazionali (Impact)
100 articoli pubblicati su riviste nazionali
50 capitoli di libri
1 Trattato “Oncologia Medica” 2005
1Trattato “La Medicina Oncologica” 2013"
    :pub="true"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Cascinu",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
